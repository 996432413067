import { usePathData } from "@/lib/contexts/appContext"
import Blocks from "@/components/Blocks"
import Block from "@/components/wrappers/block"
import classNames from "classnames/bind"
import styles from "./group.module.css"

function Group({ block }) {
  const { template } = usePathData()
  const cx = classNames.bind(styles)

  if (block.innerBlocks === undefined) {
    return null
  }

  let width = "bleed"
  let gap = block.attrs?.blockGap ?? "normal"
  let noStyling = false
  let innerWrapper = false
  let settings = {}

  if (template === "article") {
    width = "column"
    gap = "small"
    innerWrapper = block?.attrs?.theme === "transparent" ? false : true
    if (block?.attrs.theme === "transparent") {
      settings = {
        "core/buttons": { width: "bleed" },
        "core/heading": { width: "bleed" },
        "core/paragraph": { width: "bleed" },
        "core/list": { width: "bleed" },
        "core/quote": { width: "bleed" }
      }
    }
  }

  if (block.attrs?.noStyling) {
    noStyling = true
    width = null
    gap = null
  }

  if (block.attrs?.inFooter) {
    width = "bleed"
    gap = "none"
  }

  if (template !== "article" && block.attrs?.innerAlign === "align-left") {
    settings = {
      "core/heading": { innerAlign: "layout-align-left" },
      "core/paragraph": { innerAlign: "layout-align-left" },
      "core/list": { innerAlign: "layout-align-left" },
      "core/buttons": { innerAlign: "layout-align-left" },
      "core/image": { innerAlign: "layout-align-left" },
      "theme/link-list": { innerAlign: "layout-align-left" },
      "theme/pdf-list": { innerAlign: "layout-align-left" },
      "theme/accordion": { innerAlign: "layout-align-left" }
    }
  }

  const inArticle = template === "article" && !block.attrs?.inFooter

  const classes = [
    {
      group: true,
      standard: !inArticle,
      article: inArticle,
      "in-footer": block.attrs?.inFooter,
      "in-article-head": block?.attrs?.classList === "article-head",
      alignLeft: block.attrs?.innerAlign === "align-left"
    },
    block.attrs.theme
  ]

  return (
    <Block
      id={block.attrs.id}
      block={block}
      className={cx(classes)}
      width={width}
      gap={gap}
      noStyling={noStyling}
      innerWrapper={innerWrapper}>
      <Blocks blocks={block.innerBlocks} settings={settings} />
    </Block>
  )
}

export default Group
